import { addTask } from "../utils/bugFixer";
import { Action, Reducer } from 'redux';
import * as Api from '../api/api';
import { AppThunkAction } from './';
import { getDefaultHeaders } from '../utils/utils';
import * as Notifications from 'react-notification-system-redux';
import Download from "downloadjs";
import * as MimeTypes from "mime-types";
import Moment from 'moment';

export interface DocumentState {
    requestTime: number;
    isLoading: boolean;
    firmDocuments: Array<Api.FirmDocumentModel>;
    downloadStates: { [id: string]: FirmDocumentDownloadState }
    isOpen: boolean;
}

interface FirmDocumentDownloadState {
    isLoading: boolean;
    requestTime: number;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

interface RequestFirmDocuments { type: 'REQUEST_FIRMDOCUMENTS', requestTime: number }
interface ReceiveFirmDocuments { type: 'RECEIVE_FIRMDOCUMENTS', requestTime: number, firmDocuments: Array<Api.FirmDocumentModel> }

interface RequestFirmDocumentDownload { type: 'REQUEST_FIRMDOCUMENT_DOWNLOAD', requestTime: number, firmDocumentId: any }
interface ReceiveFirmDocumentDownload { type: 'RECEIVE_FIRMDOCUMENT_DOWNLOAD', requestTime: number, firmDocumentId: any }

interface FirmDocumentOpen { type: 'FIRMDOCUMENT_OPEN' }
interface FirmDocumentClose { type: 'FIRMDOCUMENT_CLOSE' }

interface WorkdocumentRequestDownloadRatesDocument { type: 'WORKDOCUMENT_REQUEST_DOWNLOAD_RATES_DOCUMENT'; requestTime: number; workdocumentId: number }
interface WorkdocumentReceiveDownloadRatesDocument { type: 'WORKDOCUMENT_RECEIVE_DOWNLOAD_RATES_DOCUMENT'; requestTime: number; workdocumentId: number }


// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction =
    RequestFirmDocuments | ReceiveFirmDocuments
    | RequestFirmDocumentDownload
    | ReceiveFirmDocumentDownload
    | FirmDocumentOpen
    | FirmDocumentClose
    | WorkdocumentRequestDownloadRatesDocument
    | WorkdocumentReceiveDownloadRatesDocument
    ;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

let download = null;
export const actionCreators = {
    requestFirmDocuments: (requestTime: number): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        if (requestTime === getState().document.requestTime)
            return;

        let api = new Api.DocumentApi();
        let fetchTask = api.getFirmDocuments(
            { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(firmDocuments => {
                dispatch({ type: "RECEIVE_FIRMDOCUMENTS", requestTime: requestTime, firmDocuments: firmDocuments });
            })
            .catch(error => {
                dispatch({ type: "RECEIVE_FIRMDOCUMENTS", requestTime: requestTime, firmDocuments: [] });
                console.log("Error fetching documents: " + error.message)
                dispatch(Notifications.error({ message: "Error getting your documents", title: "Error", position: "tc" }) as any);
            });
        dispatch({ type: "REQUEST_FIRMDOCUMENTS", requestTime: requestTime });
        addTask(fetchTask);
    },
    downloadFirmDocument: (requestTime: number, firmDocumentId: number): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        if (getState().document.downloadStates[firmDocumentId]
            && requestTime === getState().document.downloadStates[firmDocumentId].requestTime)
            return;

        let firmDocument = getState().document.firmDocuments.find(fd => fd.firmDocumentId === firmDocumentId);
        let api = new Api.DocumentApi();
        let fetchTask = api.downloadDocument({ firmDocumentId: firmDocumentId },
            { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(response => response.blob())
            .then(blob => {
                dispatch({ type: "RECEIVE_FIRMDOCUMENT_DOWNLOAD", requestTime: requestTime, firmDocumentId: firmDocumentId });
                return Download(blob,
                    firmDocument.fileName,
                    MimeTypes.lookup(firmDocument.fileName) || "text/plain")
            })
            .catch(error => {
                dispatch({ type: "RECEIVE_FIRMDOCUMENT_DOWNLOAD", requestTime: requestTime, firmDocumentId: firmDocumentId });
                dispatch(Notifications.error({ message: "Error downloading your file", title: "Error", position: "tc" }) as any);
            });

        dispatch({ type: "REQUEST_FIRMDOCUMENT_DOWNLOAD", requestTime: requestTime, firmDocumentId: firmDocumentId });
    },
    downloadTos: (requestTime: number, lang: string): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        if (getState().document.downloadStates["tos"]
            && requestTime === getState().document.downloadStates["tos"].requestTime)
            return;

        let api = new Api.DownloadApi();
        let fetchTask = api.downloadTos({ lang: lang }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(response => response.blob())
            .then(blob => {
                dispatch({ type: "RECEIVE_FIRMDOCUMENT_DOWNLOAD", requestTime: requestTime, firmDocumentId: "tos" });
                return Download(blob,
                    "Okargo - TOS.pdf",
                    MimeTypes.lookup("Okargo.pdf") || "text/plain")
            })
            .catch(error => {
                dispatch({ type: "RECEIVE_FIRMDOCUMENT_DOWNLOAD", requestTime: requestTime, firmDocumentId: "tos" });
                dispatch(Notifications.error({ message: "Error downloading your file", title: "Error", position: "tc" }) as any);
            });
            dispatch({ type: "REQUEST_FIRMDOCUMENT_DOWNLOAD", requestTime: requestTime, firmDocumentId: "tos" });
    },
    downloadGuideCrawlers: (requestTime: number, lang: string): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        let key = "guideCrawlers";
        if (getState().document.downloadStates[key]
            && requestTime === getState().document.downloadStates[key].requestTime)
            return;

        let api = new Api.DownloadApi();
        let fetchTask = api.downloadGuideCrawlers({ lang: lang }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(response => response.blob())
            .then(blob => {
                dispatch({ type: "RECEIVE_FIRMDOCUMENT_DOWNLOAD", requestTime: requestTime, firmDocumentId: key });
                return Download(blob,
                    "SETTING UP CONNECTIONS VIA WEBCRAWLERS.pdf",
                    MimeTypes.lookup("Okargo.pdf") || "text/plain")
            })
            .catch(error => {
                dispatch({ type: "RECEIVE_FIRMDOCUMENT_DOWNLOAD", requestTime: requestTime, firmDocumentId: key });
                dispatch(Notifications.error({ message: "Error downloading your file", title: "Error", position: "tc" }) as any);
            });
        dispatch({ type: "REQUEST_FIRMDOCUMENT_DOWNLOAD", requestTime: requestTime, firmDocumentId: key });
        return fetchTask;
    },
    downloadGuideQuotation: (requestTime: number): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        let key = "downloadGuideQuotation";
        if (getState().document.downloadStates[key]
            && requestTime === getState().document.downloadStates[key].requestTime)
            return;

        let api = new Api.DownloadApi();
        let fetchTask = api.downloadGuideQuotation({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(response => response.blob())
            .then(blob => {
                dispatch({ type: "RECEIVE_FIRMDOCUMENT_DOWNLOAD", requestTime: requestTime, firmDocumentId: key });
                return Download(blob,
                    "Okargo_guide_quotation.docx",
                    MimeTypes.lookup("Okargo.pdf") || "text/plain")
            })
            .catch(error => {
                dispatch({ type: "RECEIVE_FIRMDOCUMENT_DOWNLOAD", requestTime: requestTime, firmDocumentId: key });
                dispatch(Notifications.error({ message: "Error downloading your file", title: "Error", position: "tc" }) as any);
            });
        dispatch({ type: "REQUEST_FIRMDOCUMENT_DOWNLOAD", requestTime: requestTime, firmDocumentId: key });
    },
    downloadGuideOcq: (requestTime: number): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        let key = "downloadGuideQcq";
        if (getState().document.downloadStates[key]
            && requestTime === getState().document.downloadStates[key].requestTime)
            return;

        let api = new Api.DownloadApi();
        let fetchTask = api.downloadGuideOcq({ lang: getState().seed.languages[getState().account.languageId].code }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(response => response.blob())
            .then(blob => {
                dispatch({ type: "RECEIVE_FIRMDOCUMENT_DOWNLOAD", requestTime: requestTime, firmDocumentId: key });
                return Download(blob,
                    "Okargo_guide_ocq.pdf",
                    MimeTypes.lookup("Okargo.pdf") || "text/plain")
            })
            .catch(error => {
                dispatch({ type: "RECEIVE_FIRMDOCUMENT_DOWNLOAD", requestTime: requestTime, firmDocumentId: key });
                dispatch(Notifications.error({ message: "Error downloading your file", title: "Error", position: "tc" }) as any);
            });
        dispatch({ type: "REQUEST_FIRMDOCUMENT_DOWNLOAD", requestTime: requestTime, firmDocumentId: key });
    },
    downloadApp: (requestTime: number): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        if (getState().document.downloadStates["app"]
            && requestTime === getState().document.downloadStates["app"].requestTime)
            return;

        let api = new Api.DownloadApi();
        let fetchTask = api.downloadApp({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(response => response.blob())
            .then(blob => {
                dispatch({ type: "RECEIVE_FIRMDOCUMENT_DOWNLOAD", requestTime: requestTime, firmDocumentId: "app" });
                return Download(blob,
                    "Okargo.exe",
                    MimeTypes.lookup("Okargo.exe") || "text/plain")
            })
            .catch(error => {
                dispatch({ type: "RECEIVE_FIRMDOCUMENT_DOWNLOAD", requestTime: requestTime, firmDocumentId: "app" });
                dispatch(Notifications.error({ message: "Error downloading your file", title: "Error", position: "tc" }) as any);
            });
        dispatch({ type: "REQUEST_FIRMDOCUMENT_DOWNLOAD", requestTime: requestTime, firmDocumentId: "app" });
    },
    downloadQuickstart: (requestTime: number, lang: string): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        if (getState().document.downloadStates["quickStart"]
            && requestTime === getState().document.downloadStates["quickStart"].requestTime)
            return;

        let api = new Api.DownloadApi();
        let fetchTask = api.downloadQuickstart({ lang: lang }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(response => response.blob())
            .then(blob => {
                dispatch({ type: "RECEIVE_FIRMDOCUMENT_DOWNLOAD", requestTime: requestTime, firmDocumentId: "quickStart" });
                return Download(blob,
                    "Okargo - QuickStart.pdf",
                    MimeTypes.lookup("Okargo - QuickStart.pdf") || "text/plain")
            })
            .catch(error => {
                dispatch({ type: "RECEIVE_FIRMDOCUMENT_DOWNLOAD", requestTime: requestTime, firmDocumentId: "quickStart" });
                dispatch(Notifications.error({ message: "Error downloading your file", title: "Error", position: "tc" }) as any);
            });
            dispatch({ type: "REQUEST_FIRMDOCUMENT_DOWNLOAD", requestTime: requestTime, firmDocumentId: "quickStart" });
    },
    downloadChargesGlossary: (requestTime: number): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        if (getState().document.downloadStates["chargesGlossary"]
            && requestTime === getState().document.downloadStates["chargesGlossary"].requestTime)
            return;

        let api = new Api.DownloadApi();
        let fetchTask = api.downloadChargesGlossary({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(response => response.blob())
            .then(blob => {
                dispatch({ type: "RECEIVE_FIRMDOCUMENT_DOWNLOAD", requestTime: requestTime, firmDocumentId: "chargesGlossary" });
                return Download(blob,
                    "OKARGO Charges Glossary.xlsx",
                    MimeTypes.lookup("OKARGO Charges Glossary.xlsx") || "text/plain")
            })
            .catch(error => {
                dispatch({ type: "RECEIVE_FIRMDOCUMENT_DOWNLOAD", requestTime: requestTime, firmDocumentId: "chargesGlossary" });
                dispatch(Notifications.error({ message: "Error downloading your file", title: "Error", position: "tc" }) as any);
            });
        dispatch({ type: "REQUEST_FIRMDOCUMENT_DOWNLOAD", requestTime: requestTime, firmDocumentId: "chargesGlossary" });
    },
    downloadPortsAndZones: (requestTime: number): AppThunkAction<KnownAction, void> => (dispatch, getState) => {
        if (getState().document.downloadStates["portsAndZones"]
            && requestTime === getState().document.downloadStates["portsAndZones"].requestTime)
            return;

        let api = new Api.DownloadApi();
        let fetchTask = api.downloadPortsAndZones({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(response => response.blob())
            .then(blob => {
                dispatch({ type: "RECEIVE_FIRMDOCUMENT_DOWNLOAD", requestTime: requestTime, firmDocumentId: "portsAndZones" });
                return Download(blob,
                    "OKARGO Ports and zones.xlsx",
                    MimeTypes.lookup("OKARGO Charges Glossary.xlsx") || "text/plain")
            })
            .catch(error => {
                dispatch({ type: "RECEIVE_FIRMDOCUMENT_DOWNLOAD", requestTime: requestTime, firmDocumentId: "portsAndZones" });
                dispatch(Notifications.error({ message: "Error downloading your file", title: "Error", position: "tc" }) as any);
            });
        dispatch({ type: "REQUEST_FIRMDOCUMENT_DOWNLOAD", requestTime: requestTime, firmDocumentId: "portsAndZones" });
    },
    openFirmDocument: () => <FirmDocumentOpen>{
        type: "FIRMDOCUMENT_OPEN"
    },
    closeFirmDocument: () => <FirmDocumentClose>{
        type: "FIRMDOCUMENT_CLOSE"
    },
    requestDownloadWorkdocumentDocument: (requestTime: number, workdocumentId: number): AppThunkAction<KnownAction, void> => (dispatch, getState) => {

        let api = new Api.DocumentApi();
        api.downloadWorkodocumetDocument({
            workdocumentId: workdocumentId
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(response => response.blob())
            .then(blob => {
                dispatch({ type: "WORKDOCUMENT_RECEIVE_DOWNLOAD_RATES_DOCUMENT", workdocumentId: workdocumentId, requestTime: requestTime })
                let fileName = "Okargo_Documents_" + Moment().format("YYYY_MM_DD") + ".zip";
                return Download(blob,
                    fileName,
                    MimeTypes.lookup(fileName) || "text/plain");
            })
            .catch(error => {
                dispatch({ type: "WORKDOCUMENT_RECEIVE_DOWNLOAD_RATES_DOCUMENT", workdocumentId: workdocumentId, requestTime: requestTime })
                dispatch(Notifications.error({ message: "Failed to download your document", title: "Error", position: "tc" }) as any);
            });

        dispatch({ type: "WORKDOCUMENT_REQUEST_DOWNLOAD_RATES_DOCUMENT", workdocumentId: workdocumentId, requestTime: requestTime })
    },
};
// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.


const unloadedState: DocumentState = {
    isLoading: false,
    requestTime: 0,
    firmDocuments: [],
    downloadStates: {},
    isOpen: false
};

export const reducer: Reducer<DocumentState> = (state: DocumentState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_FIRMDOCUMENTS":
            return {
                ...state,
                isLoading: true,
                requestTime: action.requestTime
            };
        case "RECEIVE_FIRMDOCUMENTS":
            if (action.requestTime !== state.requestTime)
                return state;

            return {
                ...state,
                isLoading: false,
                firmDocuments: action.firmDocuments
            };
        case "REQUEST_FIRMDOCUMENT_DOWNLOAD":
            return {
                ...state,
                downloadStates: {
                    ...state.downloadStates,
                    [action.firmDocumentId]: {
                        ...state.downloadStates[action.firmDocumentId],
                        isLoading: true,
                        requestTime: action.requestTime
                    }
                }
            };
        case "RECEIVE_FIRMDOCUMENT_DOWNLOAD":
            if (!state.downloadStates[action.firmDocumentId]
                || state.downloadStates[action.firmDocumentId].requestTime !== action.requestTime)
                return state;

            return {
                ...state,
                downloadStates: {
                    ...state.downloadStates,
                    [action.firmDocumentId]: {
                        ...state.downloadStates[action.firmDocumentId],
                        isLoading: false
                    }
                }
            };
        case "FIRMDOCUMENT_OPEN":
            return {
                ...state,
                isOpen: true
            };
        case "FIRMDOCUMENT_CLOSE":
            return {
                ...state,
                isOpen: false
            };
        case "WORKDOCUMENT_REQUEST_DOWNLOAD_RATES_DOCUMENT":
            return {
                ...state,
                downloadStates: {
                    ...state.downloadStates,
                    [action.workdocumentId]: {
                        ...state.downloadStates[action.workdocumentId],
                        isLoading: true,
                        requestTime: action.requestTime
                    }
                }
            };
        case "WORKDOCUMENT_RECEIVE_DOWNLOAD_RATES_DOCUMENT":
            if (!state.downloadStates[action.workdocumentId]
                || state.downloadStates[action.workdocumentId].requestTime !== action.requestTime)
                return state;

            return {
                ...state,
                downloadStates: {
                    ...state.downloadStates,
                    [action.workdocumentId]: {
                        ...state.downloadStates[action.workdocumentId],
                        isLoading: false
                    }
                }
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};
